<template>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
        <div class="col-xl-6 col-lg-6 col-md-8 col-12">
            <div class="ls-center">
                <h1 style="text-align: center">RTGS to RTGS Payments</h1>
            </div>
            <form class="ls-form">
                <div class="form-group">
                    <label for="voucherShop">Select Service</label>
                    <select class="form-control" id="pay_service" v-model="form.service">
                        <option selected value="Electricity">Electricity</option>
                        <option value="Water">Water</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Medical-Aid">Medical Aid</option>
                        <option value="Funerals">Funerals</option>
                        <option value="College-Fees">College Fees</option>
                        <option value="School-Fees">School Fees</option>
                        <option value="City-Councils">City Councils</option>
                        <option value="Broadband">Broadband</option>
                    </select>
                    <small v-if="errors && errors.amount" class="mobile_error text-danger">
                        {{ errors.amount }}
                    </small>
                </div>
                <div id="Electricity" v-if="form.service == 'Electricity'">
                    <div class="form-group">
                        <label for="company_name">Company name (ZETDC or ZESA)</label>
                        <select class="form-control" id="company_name" v-model="form.company_name">
                            <option selected value="ZETDC">ZETDC</option>
                            <option value="ZESA">ZESA</option>
                        </select>
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.company_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Meter Number</label>
                        <input type="text" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>
                <div id="Water" v-if="form.service == 'Water'">
                    <div class="form-group">
                        <label for="water_city">City</label>
                        <input type="text" class="form-control" id="water_city" v-model="form.water_city" />
                        <small v-if="errors && errors.water_city" class="mobile_error text-danger">
                            {{ errors.water_city }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Account/Meter Number</label>
                        <input type="text" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>
                <div id="Insurance" v-if="form.service == 'Insurance'">
                    <div class="form-group">
                        <label for="company_name">Company name</label>
                        <select class="form-control" id="company_name" v-model="form.company_name">
                            <option selected value="CBZ">CBZ</option>
                            <option value="Econet">Econet</option>
                        </select>
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.company_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_name">Account Holder Name</label>
                        <input type="text" class="form-control" id="account_name" v-model="form.account_name" />
                        <small v-if="errors && errors.account_name" class="mobile_error text-danger">
                            {{ errors.account_name }}
                        </small>
                    </div>
                </div>
                <div id="Medical-Aid" v-if="form.service == 'Medical-Aid'">
                    <div class="form-group">
                        <label for="company_name">Company name</label>
                        <select class="form-control" id="company_name" v-model="form.company_name">
                            <option selected value="CBZ">CBZ</option>
                            <option value="Econet">Econet</option>
                        </select>
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.company_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_name">Account Holder Name</label>
                        <input type="text" class="form-control" id="account_name" v-model="form.account_name" />
                        <small v-if="errors && errors.account_name" class="mobile_error text-danger">
                            {{ errors.account_name }}
                        </small>
                    </div>
                </div>
                <div id="Funerals" v-if="form.service == 'Funerals'">
                    <div class="form-group">
                        <label for="company_name">Company name</label>
                        <select class="form-control" id="company_name" v-model="form.company_name">
                            <option selected value="CBZ">CBZ</option>
                            <option value="Econet">Econet</option>
                        </select>
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.company_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_name">Account Holder Name</label>
                        <input type="text" class="form-control" id="account_name" v-model="form.account_name" />
                        <small v-if="errors && errors.account_name" class="mobile_error text-danger">
                            {{ errors.account_name }}
                        </small>
                    </div>
                </div>
                <div id="College-Fees" v-if="form.service == 'College-Fees'">
                    <div class="form-group">
                        <label for="university">University</label>
                        <select class="form-control" id="university" v-model="form.university">
                            <option selected value="NUST">NUST</option>
                            <option value="UZ">UZ</option>
                            <option value="AU">AU</option>
                        </select>
                        <small v-if="errors && errors.university" class="mobile_error text-danger">
                            {{ errors.university }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="student_id">Student ID</label>
                        <input type="text" class="form-control" id="student_id" v-model="form.student_id" />
                        <small v-if="errors && errors.student_id" class="mobile_error text-danger">
                            {{ errors.student_id }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="student_name">Student Name</label>
                        <input type="text" class="form-control" id="student_name" v-model="form.student_name" />
                        <small v-if="errors && errors.student_name" class="mobile_error text-danger">
                            {{ errors.student_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Bank Account Number</label>
                        <input type="number" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="bank">Bank</label>
                        <input type="text" class="form-control" id="bank" v-model="form.bank" />
                        <small v-if="errors && errors.bank" class="mobile_error text-danger">
                            {{ errors.bank }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>
                <div id="School-Fees" v-if="form.service == 'School-Fees'">
                    <div class="form-group">
                        <label for="school">School</label>
                        <input type="text" class="form-control" id="school" v-model="form.school" />
                        <small v-if="errors && errors.school" class="mobile_error text-danger">
                            {{ errors.school }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="student_name">Student Name</label>
                        <input type="text" class="form-control" id="student_name" v-model="form.student_name" />
                        <small v-if="errors && errors.student_name" class="mobile_error text-danger">
                            {{ errors.student_name }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Bank Account Number</label>
                        <input type="number" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="bank">Bank</label>
                        <input type="text" class="form-control" id="bank" v-model="form.bank" />
                        <small v-if="errors && errors.bank" class="mobile_error text-danger">
                            {{ errors.bank }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>
                <div id="City-Councils" v-if="form.service == 'City-Councils'">
                    <div class="form-group">
                        <label for="water_city">City</label>
                        <input type="text" class="form-control" id="water_city" v-model="form.water_city" />
                        <small v-if="errors && errors.water_city" class="mobile_error text-danger">
                            {{ errors.water_city }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Account Number</label>
                        <input type="text" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>
                <div id="Broadband" v-if="form.service == 'Broadband'">
                    <div class="form-group">
                        <label for="company_name">Company Name</label>
                        <input type="text" class="form-control" id="company_name" v-model="form.company_name" />
                        <small v-if="errors && errors.water_city" class="mobile_error text-danger">
                            {{ errors.water_city }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="account_number">Account Number</label>
                        <input type="text" class="form-control" id="account_number" v-model="form.account_number" />
                        <small v-if="errors && errors.account_number" class="mobile_error text-danger">
                            {{ errors.account_number }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="text" class="form-control" id="amount" v-model="form.amount" />
                        <small v-if="errors && errors.amount" class="mobile_error text-danger">
                            {{ errors.amount }}
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label for="phone-input">Zim Contact Phone Number</label>
                    <input type="phone" class="form-control" id="phone-input" v-model="form.phone" />
                    <small v-if="errors && errors.phone" class="mobile_error text-danger">
                        {{ errors.phone }}
                    </small>
                </div>
                <div class="form-group form-check">
                    <input required type="checkbox" class="form-check-input" id="agreeToTerms" v-model="agreeToTerms" />
                    <label class="form-check-label" for="agreeToTerms">
                        I have Read the Terms and Agreements</label>
                    <small v-if="errors && errors.terms" class="terms_error text-danger">
                        {{ errors.terms }}
                    </small>
                </div>
                <div class="justify-content-between mb-4 mt-4">
                    <div>
                        &nbsp;&nbsp;
                        <button v-if="auth.isAuthenticated" :disabled="isLoading" type="submit"
                            class="btn btn-primary ls-submit-button" @click.prevent="addToCart()">
                            <i v-if="!isLoading" class="icon-cart-add"></i>
                            {{ isLoading ? "Processing..." : "Add to cart" }}
                            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                            <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
                        </button>
                        <router-link v-else :to="{ name: 'login' }">
                            <button type="submit" class="btn btn-primary ls-submit-button">
                                <i v-if="!isLoading" class="icon-cart-add"></i>
                                Add to Cart
                            </button>
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
    name: "ZwlToZwl",
    components: {},
    data() {
        return {
            form: {
                service: "",
                company_name: "",
                account_number: "",
                account_name: "",
                bank: "",
                water_city: "",
                network: "",
                phone: "",
                university: "",
                school: "",
                student_id: "",
                student_name: "",
                amount: "",
            },
            agreeToTerms: false,
            isLoading: false,
            errors: {
                terms: "",
                service: "",
                company_name: "",
                account_number: "",
                account_name: "",
                bank: "",
                water_city: "",
                network: "",
                phone: "",
                university: "",
                school: "",
                student_id: "",
                student_name: "",
                amount: "",
            },
        };
    },
    computed: {
        ...mapState({
            auth: (state) => state.auth,
            cart: (state) => state.cart,
        }),
    },
    methods: {
        addToCart() {
            if (this.isValid()) {
                this.form.phone = this.form.phone.replace(/^0+/, "");
                let voucherName = "Simple Paynow";
                const quantity = 1;
                const product = {
                    id: this.generateUUID(),
                    name: voucherName,
                    quantity: quantity,
                    phone: this.form.phone,
                    price: this.form.amount,
                    total: this.form.amount * quantity,
                    props: {
                        //network: this.form.network,
                    },
                    type: "simple-paynow",
                    shop: 2,
                };

                console.log("Simple Paynow");
                console.log(product);
                this.$store.dispatch("cart/addToCart", {
                    product: product,
                    quantity: 1,
                });

                // this.$toasted.success("DialFrom263 added to cart");
                this.$toasted.show("Simple Paynow added to cart", {
                    duration: 20000,
                    action: [
                        {
                            text: "Checkout",
                            push: {
                                name: "cart",
                            },
                        },
                        {
                            text: "Continue shopping",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    ],
                });
            }
        },

        generateUUID() {
            // Public Domain/MIT
            let d = new Date().getTime(); //Timestamp
            let d2 =
                (typeof performance !== "undefined" &&
                    performance.now &&
                    performance.now() * 1000) ||
                0; //Time in microseconds since page-load or 0 if unsupported
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    let r = Math.random() * 16; //random number between 0 and 16
                    if (d > 0) {
                        //Use timestamp until depleted
                        r = (d + r) % 16 | 0;
                        d = Math.floor(d / 16);
                    } else {
                        //Use microseconds since page-load if supported
                        r = (d2 + r) % 16 | 0;
                        d2 = Math.floor(d2 / 16);
                    }
                    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
                }
            );
        },
    },
};
</script>