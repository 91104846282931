<template>
  <section class="ls-page">
    <div
      class="ps-section__header ls-page-header-no-bg"
      style="background-image: url(../img/cover_paydirect.jpg)"
    ></div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        Pay for your families Zimbabwean services with the greatest of ease with Pahukama.
      </h3>

      <div class="">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="ls-nav-head nav-link active"
              id="nav-usd-to-usd-tab"
              data-toggle="tab"
              href="#nav-usd-to-usd"
              role="tab"
              aria-controls="nav-usd-to-usd"
              aria-selected="true"
              >USD to USD</a
            >
            <a
              class="ls-nav-head nav-link"
              id="nav-voucher-recharge-tab"
              data-toggle="tab"
              href="#nav-usd-to-rtgs"
              role="tab"
              aria-controls="nav-usd-to-rtgs"
              aria-selected="true"
              >USD to RTGS</a
            >
            <a
              class="ls-nav-head nav-link"
              id="nav-rtgs-to-rtgs-tab"
              data-toggle="tab"
              href="#nav-rtgs-to-rtgs"
              role="tab"
              aria-controls="nav-rtgs-to-rtgs"
              aria-selected="false"
              >RTGS to RTGS</a
            >
          </div>
        </nav>
        <div class="tab-content ls-tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-usd-to-usd"
            role="tabpanel"
            aria-labelledby="nav-usd-to-usd-tab"
          >
            <UsdToUsd></UsdToUsd>
          </div>
          <div
            class="tab-pane fade"
            id="nav-usd-to-rtgs"
            role="tabpanel"
            aria-labelledby="nav-usd-to-rtgs-tab"
          >
            <UsdToZwl></UsdToZwl>
          </div>
          <!-- <div class="tab-pane fade" id="nav-rtgs-to-rtgs" role="tabpanel" aria-labelledby="nav-rtgs-to-rtgs-tab">
            <ZwlToZwl></ZwlToZwl>
          </div> -->
        </div>
      </div>
      <div class="row" style="display: none">
        <div class="col-12">
          <h1 class="ls-center">Buy ZESA Token</h1>
          <!--<RechargeZesa></RechargeZesa>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import RechargeZesa from "./RechargeZesa";
import UsdToUsd from "./UsdToUsd/UsdToUsd";
import UsdToZwl from "./UsdToZwl/UsdToZwl";
import ZwlToZwl from "./ZwlToZwl/ZwlToZwl";
export default {
  name: "SimplePayDirect",
  components: { UsdToUsd, UsdToZwl, ZwlToZwl },
};
</script>
